import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../components/Banner/Banner';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Layout from '../components/Layout/layout';
import About from '../Views/Website/About/About';

const AboutPage: React.FC = () => {
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: About Us</title><meta charSet='utf-8' />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "", Text: "About" }
      ]} />
      <About />
    </Layout>
  );
};

export default AboutPage;
